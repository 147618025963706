(function ($) {
  /**
   * Show and hide search form
   */
  var $html = $('html');
  var $template = $('.js-end-esearch-nav');
  var $openSearchLink = $('.js-esearch-open');
  var $inputTrigger = $('.gnav-util-trigger', $template);
  var $currentBreakPoint = Unison.fetch.now();
  var $form = $('.js-esearch-form');
  var inputHidden = $form.hasClass('esearch-nav-input-hidden');

  function showHideSearch() {
    $currentBreakPoint = Unison.fetch.now();
    var event =
      $currentBreakPoint.name !== 'landscape' ? 'click.esearch' : 'click.esearch touchstart.esearch mouseover.esearch';
    var $searchInputBox = $('.js-predictive-search-term');

    if (inputHidden) {
      $openSearchLink.off('click.esearch touchstart.esearch mouseover.esearch').on(event, function (event) {
        event.preventDefault();
        if ($inputTrigger.prop('checked') === true) {
          return false;
        }

        $inputTrigger.prop('checked', true);
        $searchInputBox.focus();
        $html.toggleClass('active-utility-overlay', true);
        // Remove active-gnav class from html
        $html.toggleClass('active-gnav', false);
        $('.js-gnav-util-close', $form).show();
      });
      // Close utility overlay when moving the mouse out or clicking outside of it:
      $html.once().on(
        'click.esearch mouseout.esearch mouseover.esearch',
        _.debounce(function (event) {
          // Avoid mouseout on hover input field
          if (event.type !== 'click') {
            if (event.relatedTarget === null || event.relatedTarget.nodeName.toLowerCase() === 'input') {
              return;
            }
          }

          if (
            $html.hasClass('active-utility-overlay') &&
            ((event.type === 'mouseout' && !event.relatedTarget) ||
              !$(event.target).parents('.site-header-formatter__header').length)
          ) {
            $html.toggleClass('active-utility-overlay', false);
            $inputTrigger.prop('checked', false);
          }
        }, 300)
      );
    } else if ($currentBreakPoint.name !== 'landscape') {
      $form.hide();
      $openSearchLink.on('click', function (event) {
        event.preventDefault();
        var pageWidth = $(window).width();

        $form.width(pageWidth);
        $form.toggle();
      });
    } else {
      $form.show();
    }

    $(document).on('endeca.typeahead.complete', function () {
      $('.js-gnav-util-close').show();
    });

    $(document).on('endeca.typeahead.iconClose', function () {
      $html.toggleClass('active-utility-overlay', false);
      $inputTrigger.prop('checked', false);
    });
  }

  showHideSearch();
  Unison.on('change', function () {
    showHideSearch();
  });
})(jQuery);
